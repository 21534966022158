import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles/discoverCTA.scss";

const DiscoverCTA = ({
  discoverCTATitle,
  discoverCTAText,
  discoverCTAImage,
  ctaType,
  discoverCTALargeMobile,
  discoverCTAMobile,
  discoverCTAButtonText,
  applyButton,
}) => {
  if (ctaType === "reversed") {
    return (
      <div id="discoverCTAReversed">
        <Container fluid className="px-xxl-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col
              xs={12}
              lg={6}
              className="p-xxl-5 p-xl-1 p-lg-3 p-md-5 p-3 order-last cta-text-block"
            >
              <div className="m-lg-2 m-md-4 m-2 m-xl-3 mx-xxl-5">
                <Row>
                  <Col xxl={{ span: 7, offset: 2 }} xs={12}>
                    <h2 className="mb-4">{discoverCTATitle}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col xxl={{ span: 9, offset: 2 }} xs={12}>
                    <p className="mb-4">{discoverCTAText}</p>
                    <a href="#mu-hero" className="btn btn-secondary">
                      Learn More{" "}
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                          fill="#323337"
                        />
                      </svg>
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} lg={6} className="px-0">
              <img
                src={discoverCTAImage}
                alt="Meridian Landscape"
                className="img-fluid d-none d-xl-block"
              />
              <img
                src={discoverCTALargeMobile}
                alt="Meridian Landscape"
                className="img-fluid d-none d-lg-block d-xl-none"
              />
              <img
                src={discoverCTAMobile}
                alt="Meridian Landscape"
                className="img-fluid d-block d-lg-none"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else if (ctaType === "basic") {
    return (
    <div id="discoverCTA" className="meridian-blue-bg">
      <Container fluid="lg" className="py-4 py-xl-5">
        <Row className="justify-content-between py-4 py-xl-5 cta-text-block align-items-center">
          <Col xs={12} md={8} xxl={9}>
            <h2 className="white-text mb-4">{discoverCTATitle}</h2>
            { discoverCTAText && <>
              <p>{discoverCTAText}</p>
            </>}
          </Col>
          <Col xs={12} md={3} className="text-end">
            <a href="#mu-hero" className="btn btn-secondary">{discoverCTAButtonText}{" "}
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                  fill="#323337"
                />
              </svg>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
    );
  } else {
    return (
      <div id="discoverCTA">
        <Container fluid className="px-xxl-0">
          <Row className="align-items-center justify-content-lg-between justify-content-center">
            <Col
              xs={12}
              md={10}
              lg={6}
              className="px-xxl-5 p-xl-1 p-lg-3 p-md-5 p-3 order-last order-lg-first cta-text-block"
            >
              <div className="m-lg-2 m-md-4 m-2 m-xl-3 mx-xxl-5">
                <Row>
                  <Col xxl={{ span: 9, offset: 2 }} xs={12}>
                    <h2 className="mb-4">{discoverCTATitle}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col xxl={{ span: 10, offset: 2 }} xs={12}>
                    <p className="mb-4">{discoverCTAText}</p>
                    <a href="#mu-hero" className="btn btn-secondary">
                      Learn More{" "}
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                          fill="#323337"
                        />
                      </svg>
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} lg={6} className="px-0">
              <img
                src={discoverCTAImage}
                alt="Meridian Landscape"
                className="img-fluid d-none d-xl-block"
              />
              <img
                src={discoverCTALargeMobile}
                alt="Meridian Landscape"
                className="img-fluid d-none d-lg-block d-xl-none"
              />
              <img
                src={discoverCTAMobile}
                alt="Meridian Landscape"
                className="img-fluid d-block d-lg-none"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

export default DiscoverCTA;
